<template>
  <div class="cart-list__item overflow-auto" :style="{'height' : `${maxHeight}px`}">
    <div class="d-flex justify-content-between py-3">
        <span class="font-14 font-400">Aktifkan Harga Semi & Grosir</span>
        <el-switch
        v-if="cartHaveDetailPrice"
          v-model="toggleGroceryPrice"
          active-color="#6ACDEC"
          inactive-color="#EBEBEB"
          @change="groceryPriceToggled">
        </el-switch>
      </div>
    <div
      v-for="(product, index) in productAvailable" 
      :key="product.product_detail_id"      
      class="d-flex wrapper font-roboto" :class="{'no-border' : index + 1 === productAvailable.length}">
      <div 
        class="product-image my-auto "
        v-lazy-container="vLazyProductImage">
        <img 
          :data-src="product.files[0].value ">
      </div>
      <div class="product-info ms-3 my-auto d-flex flex-column">
        <span class="font-14 font-500">
          {{product.name}} 
        </span>
        <div class="d-flex gap-2 align-items-center">
          <span class="font-roboto font-14">
            {{
                formatRupiah(
                toggleGroceryPrice ? productPriceDetail(product).price :
                product.discounted_price ? product.discounted_price :
                product.price, 'Rp.'
                ) 
            }}
          </span>
          <div class="bg-grey-0 text-grey-90 p-2 rounded-pill font-10" v-if="productPriceDetail(product).price_type && toggleGroceryPrice">
            {{productPriceDetail(product).price_type}}
          </div>
          <button v-if="productHaveDetailPrice(product)" class="btn border-0 p-0" data-bs-toggle="modal" data-bs-target="#popupProductPrice" @click="SELECT_PRODUCT(product)" >
            <img src="@/assets/icons/information-icon.svg" alt="information price">
          </button>
        </div>
        <span 
          v-if="product.type === 'combination'"
          class="font-12">
          Variasi : {{product.variant_name}}
        </span>
          <el-input-number :disabled="cartLoading" class="mt-2" v-model="product.quantity"  @change="editProduct(product.quantity, product.product_detail_id)" :min="1" :max="product.stock"></el-input-number>

      </div>
      
      <div class="d-flex flex-column ms-auto">
        <button class="btn border-0 p-0 align-self-end" @click="deleteProduct(product.product_detail_id)">
            <img  src="@/assets/icons/close.png" alt="close">
          </button>
        <span class="font-14 my-auto">
          {{formatRupiah(( toggleGroceryPrice ? productPriceDetail(product).price :
                product.discounted_price ? product.discounted_price :
                product.price) * product.quantity, 'Rp')}}
        </span>
      </div>
    </div>
    
    <div
      v-for="(product, index) in productUnavailable" 
      :key="product.product_detail_id"      
      class="d-flex wrapper font-roboto unavailable-product" :class="{'no-border' : index + 1 === productUnavailable.length}">
      <div 
        class="product-image my-auto "
        v-lazy-container="vLazyProductImage">
        <img 
          :data-src="product.files[0].value ">
      </div>
      <div class="product-info ms-3 my-auto d-flex flex-column">
        <span class="font-14 font-500">
          {{product.name}} 
        </span>
        <div class="d-flex gap-2 align-items-center">
          <span class="font-roboto font-14">
            {{
                formatRupiah(
                toggleGroceryPrice ? productPriceDetail(product).price :
                product.discounted_price ? product.discounted_price :
                product.price, 'Rp.'
                ) 
            }}
          </span>
          <div class="bg-grey-0 text-grey-90 p-2 rounded-pill font-10" v-if="productPriceDetail(product).price_type && toggleGroceryPrice">
            {{productPriceDetail(product).price_type}}
          </div>
          <button v-if="productHaveDetailPrice(product)" class="btn border-0 p-0" data-bs-toggle="modal" data-bs-target="#popupProductPrice" @click="SELECT_PRODUCT(product)" >
            <img src="@/assets/icons/information-icon.svg" alt="information price">
          </button>
        </div>
        <span 
          v-if="product.type === 'combination'"
          class="font-12">
          Variasi : {{product.variant_name}}
        </span>
          <el-input-number :disabled="cartLoading" class="mt-2" v-model="product.quantity"  @change="editProduct(product.quantity, product.product_detail_id)" :min="0" :max="product.stock"></el-input-number>

      </div>
      
      <div class="d-flex  flex-column ms-auto">
        <button class="btn  delete-product border-0 p-0 align-self-end" @click="deleteProduct(product.product_detail_id)">
            <img  src="@/assets/icons/close.png" alt="close">
          </button>
        <span class="font-14 my-auto">
          {{formatRupiah(( toggleGroceryPrice ? productPriceDetail(product).price :
                product.discounted_price ? product.discounted_price :
                product.price) * product.quantity, 'Rp')}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'
import productMixins from '@/mixins/product.mixins'
import { mapState, mapActions ,mapMutations, mapGetters } from 'vuex'


export default {
  mixins: [IhsMixin, productMixins],
  data() {
    return {
      maxHeight: 0,
      toggleGroceryPrice: false
    }
  },
  computed: {
    ...mapState('user', ['customerSelected']),
    ...mapState('payment', ['usePoint']),
    ...mapState('cart', ['cartList', 'cartLoading', 'voucher', 'cart', 'extraValue', 'cartUseGroceryPrice']),
    ...mapState('numpad', ['extraValue']),
    ...mapGetters('cart', [
      'productTotal',
      'subtotal', 
      'discount', 
      'total',
      'voucherAmmount',
      'finalPrice',
      'productAvailable',
      'productUnavailable'   
    ]),
    cartHaveDetailPrice() {
      
      const productsSale = this.productAvailable.filter(product => {
        return ( product.semi_price && product.semi_price < product.price) || (product.grocery_price && product.grocery_price < product.price)
      })

      if (productsSale.length) {
        return true
      } else {
        return false
      }
      
    }
  },
     watch: {
      extraValue() {
        this.getCalculatedHeight()
      },
      usePoint() {
        this.getCalculatedHeight()
      },
      productAvailable : { 
        handler :function () {
          this.getCalculatedHeight()
        },
        deep: true,
        immediate: true
      }
    },
  methods:{
    ...mapActions('cart', ['editProductCart', 'deleteProductCart']),
    ...mapActions('product', ['getProduct']),
    ...mapMutations('product', [
      'SELECT_PRODUCT',
      'SELECT_PRODUCT_QUANTITY'
    ]),
     ...mapMutations('cart', [
      'SET_CART_LOADING',
      'SET_CART_USE_GROCERY_PRICE'
    ]),
 
    getCalculatedHeight () {
    
       let callback = () => {
            const containerDOM = document.querySelector(".cart-container");
            const stopwatchDOM = document.querySelector(".stopwatch-container");
            const cartHeaderDOM = document.querySelector(".cart-header");
            const cartFooterDOM = document.querySelector(".cart-footer");
            this.maxHeight = containerDOM.offsetHeight - (stopwatchDOM.offsetHeight + cartHeaderDOM.offsetHeight + cartFooterDOM.offsetHeight + 30)
        };
      setTimeout(callback, 300)

    },
    async editProduct(qty, id) {
      try {
        this.SET_CART_LOADING(true)
        const payload = {
          data: {
            product_detail_id: id,
            quantity: qty,
            phone_number: this.customerSelected.phone_number
          },
        }
  
        const response = await this.editProductCart(payload)
        
        if (response.data.status_code === 200) {
          this.SET_CART_LOADING(false)
        }

      } catch (error) {
        this.SET_CART_LOADING(false)
        this.$message({
					showClose: true,
					message: error.message,
					type: "error"
				});
      }
  
    },
    async deleteProduct(id) {
      const payload = {
        data: {
              product_detail_id: id,
              phone_number: this.customerSelected.phone_number
        },
      }
      
      await this.deleteProductCart(payload)

    },
    selected(product){
      this.SELECT_PRODUCT(product.product)
      this.SELECT_PRODUCT_QUANTITY(product.quantity)
    },
    groceryPriceToggled() {
      this.SET_CART_USE_GROCERY_PRICE(this.toggleGroceryPrice)
    }
  },
  mounted() {
    const _this = this
    this.toggleGroceryPrice = this.cartUseGroceryPrice
    window.addEventListener('resize', () => {
      _this.getCalculatedHeight()
    });

  }
}
</script>

<style lang="scss" scoped>
.unavailable-product {
  position: relative;
}

.unavailable-product::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Adjust the opacity value as needed */
  pointer-events: none;
  z-index: 1;
}
   
  .btn.delete-product {
    position: relative;
    z-index: 2;
  }
</style>